/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { isMobile, isDesktop } from 'react-device-detect';
import * as moment from 'moment';


const { apiURL } = require('../../config/keys');

const PAGE_SIZE = 100;

class SalesHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      salesData: [],
      offset: 0,
    };
    this.getSalesHistory = this.getSalesHistory.bind(this);
    this.close = this.close.bind(this);
    this.getSalesStore = this.getSalesStore.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.hasNextPage = this.hasNextPage.bind(this);
    this.hasPreviousPage = this.hasPreviousPage.bind(this);
    this.getOperationName = this.getOperationName.bind(this);
  }

  componentWillMount() {
    this.getSalesHistory();
  }

  getSalesHistory() {
    const { user } = this.props.auth;
    const { item } = this.props;
    const query = {
      order_no: item._id.order_no,
      limit: PAGE_SIZE,
      offset: this.state.offset,
    };
    axios.post(`${apiURL}api/operations/get_sales`, query)
      .then((response) => {
        console.log(response);
        this.setState({
          salesData: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
        });
        window.alert(`Midagi läks valesti\n ${error}`);
      });
  }

  nextPage() {
    this.setState({
      offset: this.state.offset + PAGE_SIZE,
    }, () => {
      this.getSalesHistory();
    });
  }

  previousPage() {
    this.setState({
      offset: this.state.offset - PAGE_SIZE,
    }, () => {
      this.getSalesHistory();
    });
  }

  hasNextPage() {
    return this.state.salesData.length === PAGE_SIZE;
  }

  hasPreviousPage() {
    return this.state.offset > 0;
  }

  close() {
    this.setState({
      error: false,
    }, () => {
      this.props.closeModal();
    });
  }

  getOperationName(operationType) {
    switch (operationType) {
      case 'PURCHASE':
        return 'Ost';
      case 'SALE':
        return 'Müük';
      case 'MAIN_TO_SUB':
        return 'Kesklaost vahelattu';
      case 'SUB_TO_MAIN':
        return 'Vahelaost kesklattu';
      default:
        return operationType;
    }
  }

  getSalesStore(store_id) {
    const query = {
      store_id,
    };
    axios.post(`${apiURL}api/stores/get_by_id`, query)
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
        });
      });
  }


  render() {
    const {
      modalState, closeModal, item, title,
    } = this.props;

    if (isMobile) {
      return (
          <div className="modal is-active">
              <div className="modal-background" onClick={ closeModal } />
              <div className="modal-card" style={ styles.mobileContainer }>
                  <header className="modal-card-head" style={ {
                    borderRadius: 0, borderColor: '#F9F9FA', backgroundColor: '#F9F9FA', padding: '16px',
                  } }>
                      <p className="modal-card-title"><h5 style={ styles.header }>Varuosa ajalugu</h5></p>
                      <button className="delete" onClick={ closeModal } />
                  </header>
                  <section className="modal-card-body" style={ styles.mobileSectionContainer }>
                      <div className="columns">
                          <div className="column is-16">
                              {this.state.salesData.length > 0
                                ? <div>
                                    {this.state.salesData.map(sale => (
                                        <table class="is-fullwidth" onClick={ this.toggleDetails } style={ {
                                          marginTop: '10px', marginBottom: '10px', backgroundColor: 'white', tableLayout: 'inherit', minWidth: '380px', width: '100%',
                                        } }>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Kuupäev</td>
                                                    <td>{moment(sale.created).format('DD/MM/YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Tehing</td>
                                                    <td>{this.getOperationName(sale.operation_type)}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Vaheladu</td>
                                                    <td>{sale.store.name}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Kogus(tk)</td>
                                                    <td>{sale.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Kommentaar</td>
                                                    <td>{sale.comment}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    ))} </div>
                                : <table class="is-fullwidth" onClick={ this.toggleDetails } >
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td>Ajalugu puudub</td>
                                        </tr>
                                    </tbody>
                                </table> }


                          </div>
                      </div>
                  </section>
                  <footer className="modal-card-foot" style={ styles.footerContainer }>
                      <a className="button" onClick={ this.close } style={ styles.cancelButton }>KATKESTA</a>

                  </footer>
              </div>
          </div>
      );
    }
    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={ closeModal } />
            <div className="modal-card" style={ styles.container }>
                <header className="modal-card-head" style={ { borderRadius: 0, borderColor: '#F9F9FA', backgroundColor: '#F9F9FA' } }>
                    <p className="modal-card-title"><h5 style={ styles.header }>{`Varuosa ${item._id.order_no} ajalugu`}</h5></p>
                    <button className="delete" onClick={ closeModal } />
                </header>
                <section className="modal-card-body" style={ styles.sectionContainer }>
                    <div className="columns">
                        <div className="column is-16">
                            {this.state.salesData.length > 0
                              ? <table class="table is-fullwidth" style={ {
                                backgroundColor: 'transparent', borderCollapse: 'separate', borderSpacing: '0 1em',
                              } }>
                                  <thead class="is-fullwidth">
                                      <tr>
                                          <th>Kuupäev</th>
                                          <th>Tehing</th>
                                          <th>Vaheladu</th>
                                          <th>Kogus(tk)</th>
                                          <th>Kommentaar</th>
                                      </tr>
                                  </thead>
                                  <tbody class="is-fullwidth">
                                      {this.state.salesData.map(sale => (
                                          <tr style={ { backgroundColor: '#FFFFFF', border: 0 } }>
                                              <td style={ { border: 0 } }>{moment(sale.created).format('DD/MM/YYYY')}</td>
                                              <td style={ { border: 0 } }>{this.getOperationName(sale.operation_type)}</td>
                                              <td style={ { border: 0 } }>{sale.store.name}</td>
                                              <td style={ { border: 0 } }>{sale.amount}</td>
                                              <td style={ { border: 0 } }>{sale.comment}</td>
                                          </tr>
                                      ))}
                                  </tbody>
                              </table>
                              : <table class="table is-fullwidth" style={ {
                                backgroundColor: 'transparent', borderCollapse: 'separate', borderSpacing: '0 1em',
                              } }>
                                  <thead class="is-fullwidth">
                                      <tr>
                                          <th>Ajalugu puudub</th>
                                      </tr>
                                  </thead>
                              </table> }
                            <div>
                                <a className="button" onClick={ this.previousPage } style={ {
                                  backgroundColor: '#677C98', width: '119px', height: '36px', borderColor: '#677C98', borderWidth: 2, borderRadius: 0, color: '#FFFFFF', marginLeft: '4px',
                                } } disabled={ !this.hasPreviousPage() }>Eelmine</a>
                                <a className="button" onClick={ this.nextPage } style={ {
                                  backgroundColor: '#677C98', width: '119px', height: '36px', borderColor: '#677C98', borderWidth: 2, borderRadius: 0, color: '#FFFFFF', marginLeft: '4px',
                                } } disabled={ !this.hasNextPage() }>Järgmine</a>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot" style={ styles.footerContainer }>
                    <a className="button" onClick={ this.close } style={ styles.cancelButton }>KATKESTA</a>

                </footer>
            </div>
        </div>
    );
  }
}


SalesHistory.propTypes = {
  closeModal: PropTypes.func,
  modalState: PropTypes.bool,
  showError: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  item: PropTypes.object,
  title: PropTypes.string,
  operationType: PropTypes.string.isRequired,
};

const styles = {
  container: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
  },
  sectionContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    padding: '16px',
  },
  mobileSectionContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    padding: '0px',
  },
  footerContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    justifyContent: 'flex-end',
    padding: '16px',
  },

  mobileContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
  },
  input: {
    height: '42px',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderRadius: 0,
  },
  header: {
    fontSize: 24,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#333333',
  },
  label: {
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#4F4F4F',
    letterSpacing: '0.75px',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: '1px',
    borderRadius: 0,
    color: '#677C98',
    marginRight: '0px',
  },
  submitButton: {
    backgroundColor: '#677C98',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: 2,
    borderRadius: 0,
    color: '#FFFFFF',
    marginLeft: '4px',
  },
  errorMessage: {
    textAlign: 'center',
    color: 'red',
  },
  overMin: {
    textAlign: 'center',
    border: 0,
    color: 'black',
  },
  underMin: {
    textAlign: 'center',
    border: 0,
    color: 'red',
  },
  overMinDetail: {
    border: 0,
    color: 'black',
  },
  underMinDetail: {
    border: 0,
    color: 'red',
  },
  rowNormal: {
    backgroundColor: '#FFFFFF',
    border: 0,
  },
  rowWarning: {
    backgroundColor: 'red',
    border: 2,
    borderColor: '#FFFFFF',
    borderLeftColor: 'red',
  },
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SalesHistory);
