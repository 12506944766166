import {
  QUERY_CHANGED,
} from '../actions/types';

const initialState = {
  query: 'qwe',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case QUERY_CHANGED:
      return { ...state, query: action.payload };
    default:
      return state;
  }
}
