import React, { Component } from 'react';
import {
  BrowserRouter as Router, Route, Switch, HashRouter,
} from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import Navbar from './components/layout/Navbar';
import Table from './components/main/Table';
import LogIn from './components/main/LogIn';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';


// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // TODO: Clear current Profile

    // Redirect to login
    window.location.href = '/';
  }
}

class App extends Component {
  render() {
    return (
        <Provider store={ store }>
            <HashRouter>
                <div className="App">
                    <Navbar/>

                    <section class="section" className="hero is-fullheight" style={ { backgroundColor: '#F2F2F2', paddingTop: '36px' } }>
                        <div>
                            <Route exact path="/" component={ LogIn }/>
                            <Route exact path="/login" component={ LogIn }/>
                            <Route exact path="/table" component={ Table }/>
                        </div>
                    </section>
                </div>
            </HashRouter>
        </Provider>
    );
  }
}

export default App;
