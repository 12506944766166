import React, { Component } from 'react';
import QS from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import AddItem from './AddItem';
import Scanner from './Scanner';
import { getQuery, getItems, getStores } from '../../actions/tableActions';
import RegisterStore from './RegisterStore';

class Tablehead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalState: false,
      scanning: false,
      queryText: '',
      store: '',
      scanResults: '',
      isShowHidden: false,
    };
  }

  componentDidMount() {
    this.props.getStores();
    const values = QS.parse(this.props.location.search);
    this.setState({
      queryText: values.queryText || '',
      store: values.store || '',
    }, () => {
      this.makeQuery();
    });
  }

  toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalState;

      return { modalState: newState };
    });
    this.props.getItems();
  }

  toggleScanner = () => {
    this.setState((prev, props) => {
      const newState = !prev.scanning;

      return { scanning: newState };
    });
  }

  onBarcodeDetected = (result) => {
    this.onChange({
      target: {
        name: 'queryText',
        value: `kood:${result}`,
      },
    });
  }

  makeQuery = () => {
    if (this.state.queryText === '' && !this.state.store && !this.state.isShowHidden) {
      this.props.getItems();
    } else {
      const queryText = {
        term: this.state.queryText,
        store: this.state.store,
        showHidden: this.state.isShowHidden,
      };

      this.props.getQuery(queryText);
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.handleUrlParams();
      this.makeQuery();
    });
  }

  onCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked }, () => {
      this.handleUrlParams();
      this.makeQuery();
    });
  }

  handleUrlParams = () => {
    this.props.history.push({
      search: [
        `queryText=${this.state.queryText}`,
        `store=${this.state.store}`,
        `showHidden=${this.state.isShowHidden}`,
        this.props.isInventuur ? `inventuur=${this.props.isInventuur}` : '',
      ].filter(x => !!x).join('&'),
    });
  }

  showStoreSelector = () => this.props.stores.length > 0

  render() {
    const { user } = this.props.auth;
    return (
        <div>
            <div className="columns is-fluid">
                <div className="column is-5 is-hidden-mobile" style={ { display: 'flex', justifyContent: 'left', paddingLeft: '14px' } }>
                    <h4 className="title is-4 is-size-2 is-size-4-mobile" style={ {
                      width: '328px', fontWeight: 'normal', fontFamily: 'sans-serif', letterSpacing: '0.25px', fontStyle: 'normal',
                    } }>Varuosade tabel</h4>
                </div>
                {user.store_type === 'MAIN' ? null : <div className="column is-2"></div> }
                <div className="column is-4" style={ {
                  display: 'flex', justifyContent: 'space-between',
                } }>
                    <input className="input" type="search" name="queryText" value={ this.state.queryText } onChange={ this.onChange } placeholder="Otsi varuosa" style={ {
                      height: '36px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0, marginLeft: '8px',
                    } }/>
                    { this.showStoreSelector() ? <select className="select" name="store" value={ this.state.store } onChange={ this.onChange } style={ {
                      height: '36px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0, marginLeft: '8px', minWidth: '100px',
                    } }>
                        <option value=""> Kõik </option>
                        {this.props.stores.map(store => <option value={ store.id }>{ store.name }</option>)}
                    </select> : null }
                    <a className="button is-4 is-fullwidth" onClick={ this.toggleScanner } style={ isMobile ? styles.scanButtonMobile : styles.scanButton }>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7 9C7 9.55 6.55 10 6 10C5.45 10 5 9.55 5 9V6C5 5.45 5.45 5 6 5H9C9.55 5 10 5.45 10 6C10 6.55 9.55 7 9 7H7V9ZM5 15C5 14.45 5.45 14 6 14C6.55 14 7 14.45 7 15V17H9C9.55 17 10 17.45 10 18C10 18.55 9.55 19 9 19H6C5.45 19 5 18.55 5 18V15ZM17 17H15C14.45 17 14 17.45 14 18C14 18.55 14.45 19 15 19H18C18.55 19 19 18.55 19 18V15C19 14.45 18.55 14 18 14C17.45 14 17 14.45 17 15V17ZM15 7C14.45 7 14 6.55 14 6C14 5.45 14.45 5 15 5H18C18.55 5 19 5.45 19 6V9C19 9.55 18.55 10 18 10C17.45 10 17 9.55 17 9V7H15Z" fill="#828282"/>
                            <line x1="6" y1="12" x2="18" y2="12" stroke="#828282" strokeWidth="2" strokeLinecap="round"/>
                        </svg>


                    </a>
                </div>
                {user.store_type === 'MAIN'
                  ? <div className="column">
                      <input type='checkbox' name='isShowHidden' value="true" checked={ this.state.isShowHidden } onChange={ this.onCheckboxChange } style={ { marginLeft: '4px' } }/> Kuva peidetud
                  </div> : null }
                {user.store_type === 'MAIN'
                  ? <div className="column is-2" style={ isMobile ? styles.addButtonMobile : styles.addButton }>
                      <a className="button is-fullwidth" onClick={ this.toggleModal } style={
                        {
                          backgroundColor: '#677C98', height: '36px', borderColor: '#677C98', borderWidth: 2, borderRadius: 0, color: '#FFFFFF',
                        }
                      }>LISA VARUOSA</a>
                  </div> : null }

            </div>
            {this.state.storeModalState ? <RegisterStore closeModal={ this.toggleStoreModal }/> : null}
            {this.state.modalState ? <AddItem closeModal={ this.toggleModal }/> : null}
            {this.state.scanning ? <Scanner onDetected={ this.onBarcodeDetected } closeModal={ this.toggleScanner }/> : null}
        </div>
    );
  }
}

const styles = {
  scanButton: {
    backgroundColor: '#FFFFFF', width: '42px', height: '36px', padding: '0px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0, marginLeft: '4px', marginRight: '0px',
  },
  scanButtonMobile: {
    backgroundColor: '#FFFFFF', width: '42px', height: '36px', padding: '0px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0, marginLeft: '4px', marginRight: '8px',
  },
  addButton: { marginRight: '0px', marginLeft: '0px' },
  addButtonMobile: { marginRight: '8px', marginLeft: '8px' },
};


Tablehead.propTypes = {
  auth: PropTypes.object.isRequired,
  getQuery: PropTypes.func.isRequired,
  getItems: PropTypes.func,
  getStores: PropTypes.func.isRequired,
  isInventuur: PropTypes.bool,
};

const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.items.stores,
});

export default connect(mapStateToProps, { getQuery, getItems, getStores })(Tablehead);
