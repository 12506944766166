import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../actions/authActions';
import RegisterStore from '../main/RegisterStore';
import RegisterUser from '../main/RegisterUser';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      storeModalState: false,
      userModalState: false,
    };

    this.toggleStoreModal = this.toggleStoreModal.bind(this);
    this.toggleUserModal = this.toggleUserModal.bind(this);
    this.goToInventuur = this.goToInventuur.bind(this);
  }

  goToInventuur() {
    this.props.history.push('/table?inventuur=true');
  }

  toggleStoreModal() {
    this.setState((prev, props) => {
      const newState = !prev.storeModalState;

      return { storeModalState: newState };
    });
    this.toggleNav();
  }

  toggleUserModal() {
    this.setState((prev, props) => {
      const newState = !prev.userModalState;

      return { userModalState: newState };
    });
    this.toggleNav();
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }));
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
    this.toggleNav();
  }


  render() {
    const { isAuthenticated, user } = this.props.auth;
    return (
        <nav className="navbar is-white is-fixed-top"
          aria-label="Main navigation"
          style={ {
            height: '48px',
          } }>
            <div className="container">
                <div className="navbar-brand">
                    <a href="/" className="navbar-item"><i className="fas fa-home" style={ { marginRight: '10px' } }></i>Gaspre</a>
                    <div className="navbar-burger burger" onClick={ this.toggleNav }>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu' }>
                    <div className="navbar-end">
                        <div className="navbar-item has-dropdown is-hoverable" style={ { visble: true } }>
                            <a href="/" className="navbar-link">{isAuthenticated ? user.name : 'Logi sisse'} </a>
                            { isAuthenticated
                              ? <div className="navbar-dropdown is-hoverable is-right">
                                  {user.store_type === 'MAIN' ? <a className="navbar-item" onClick={ this.goToInventuur }>Inventuur</a> : null }
                                  {user.store_type !== 'NONE' ? <a target="_blank" rel="noopener noreferrer" href="http://www.gaspre.ee/hinnagrupid.pdf" className="navbar-item">Hinnagrupid</a> : null }
                                  {user.store_type === 'MAIN' ? <a className="navbar-item" onClick={ this.toggleStoreModal }>Lisa vaheladu</a> : null }
                                  {user.isAdmin || user.store_type === 'MAIN' ? <a class="navbar-item" onClick={ this.toggleUserModal }>Lisa kasutaja</a> : null }
                                  <a href="/" onClick={ this.onLogoutClick.bind(this) } className="navbar-item">Logi välja</a>
                              </div>
                              : null }
                        </div>
                    </div>
                </div>
            </div>
            {this.state.storeModalState ? <RegisterStore closeModal={ this.toggleStoreModal }/> : null}
            {this.state.userModalState ? <RegisterUser closeModal={ this.toggleUserModal }/> : null}
        </nav>

    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { logoutUser })(Navbar));
