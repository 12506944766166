import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

const { apiURL } = require('../../config/keys');

class RegisterStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      isSeller: false,
      error: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.addItem = this.addItem.bind(this);
    this.close = this.close.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCheckboxChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  // Moving this to separate redux action module once user handling is done
  addItem() {
    const store = {
      name: this.state.name,
      password: this.state.password,
      store_type: 'NONE',
      isSeller: this.state.isSeller,
    };

    axios.post(`${apiURL}api/auth/register/`, store)
      .then((response) => {
        this.close();
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
      });
  }

  close() {
    this.setState({
      error: false,
    });
    this.props.closeModal();
  }


  render() {
    const { modalState, closeModal } = this.props;
    return (
        <div className="modal is-active" >
            <div className="modal-background" onClick={ closeModal } />
            <div className="modal-card" style={ styles.container }>
                <header className="modal-card-head" style={ {
                  borderRadius: 0, borderColor: '#F9F9FA', backgroundColor: '#F9F9FA', padding: '16px',
                } }>
                    <p className="modal-card-title"><h5 style={ styles.header }>Lisa uus kasutaja</h5></p>
                    <button className="delete" onClick={ closeModal } />
                </header>
                <section className="modal-card-body" style={ styles.sectionContainer }>
                    <div className="columns is-centered">
                        <div className="column is-6"><p style={ styles.label }>Nimi</p><input class="input" name="name" value={ this.state.name } onChange={ this.onChange } type="text" style={ {
                          height: '42px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0,
                        } }/></div>
                        <div className="column is-6"><p style={ styles.label }>Parool</p><input class="input" name="password" type="password" value={ this.state.password } onChange={ this.onChange } type="text" style={ {
                          height: '42px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0,
                        } }/></div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <p style={ styles.label }>Müüja roll</p><input name="isSeller" value="true" checked={ this.state.isSeller } onChange={ this.onCheckboxChange } type="checkbox" />
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot" style={ styles.footerContainer }>
                    <a className="button" onClick={ this.close } style={ styles.cancelButton }>KATKESTA</a>
                    <a className="button" onClick={ this.addItem } style={ styles.submitButton }>SALVESTA</a>

                </footer>
            </div>
        </div>
    );
  }
}


RegisterStore.propTypes = {
  closeModal: PropTypes.func,
  modalState: PropTypes.bool,
  showError: PropTypes.bool,
};

export default RegisterStore;

const styles = {
  sectionContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    padding: '16px',
  },
  container: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
  },
  footerContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    justifyContent: 'flex-end',
    padding: '16px',
  },
  input: {
    height: '42px',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderRadius: 0,
  },
  header: {
    fontSize: 24,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#333333',
  },
  label: {
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#4F4F4F',
    letterSpacing: '0.75px',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: '1px',
    borderRadius: 0,
    color: '#677C98',
    marginRight: '4px',
  },
  submitButton: {
    backgroundColor: '#677C98',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: '1px',
    borderRadius: 0,
    color: '#FFFFFF',
    marginLeft: '4px',
    float: 'right',
  },
  errorMessage: {
    textAlign: 'center',
    color: 'red',
  },
};
