import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loginUser } from '../../actions/authActions';


class LogIn extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      password: '',
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/table');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/table');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      name: this.state.name,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    return (
        <div class="hero-body">
            <div class="container has-text-centered">
                <div class="column is-4 is-offset-4">

                    <div class="box">
                        <h3 class="title is-4 is-size-2 is-size-4-mobile" style={ {
                          fontWeight: 'normal', fontFamily: 'sans-serif', letterSpacing: '0.25px', fontStyle: 'normal',
                        } }>Logi sisse</h3>
                        <form onSubmit={ this.onSubmit }>
                            <div class="field">
                                <div class="control">
                                    <input class="input is-large" name="name" placeholder="Kasutajanimi" autofocus="" style={ { borderRadius: 0 } } value={ this.state.name } onChange={ this.onChange }/>
                                </div>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <input class="input is-large" name="password" type="password" placeholder="Salasõna" style={ { borderRadius: 0 } } value={ this.state.password } onChange={ this.onChange }/>
                                </div>
                            </div>

                            <button class="button is-block is-large is-fullwidth" style={ {
                              backgroundColor: '#677C98', borderColor: '#677C98', borderWidth: 2, borderRadius: 0, color: '#FFFFFF',
                            } }>Login</button>
                            {typeof errors === 'string' || errors instanceof String ? <p style={ styles.errMessage }>Midagi läks valesti</p> : null}
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
  }
}

const styles = {
  errMessage: {
    color: 'red',
  },
};

LogIn.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(LogIn);
