import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import searchReducer from './searchReducer';
import tableReducer from './tableReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  query: searchReducer,
  items: tableReducer,
});
