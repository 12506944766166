/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile, isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import axios from 'axios';
import Sticky from 'react-sticky-el';
import * as moment from 'moment';
import { getQuery, getItems } from '../../actions/tableActions';

import EditItem from './EditItem';
import SalesHistory from './SalesHistory';
import Operationbox from './Operationbox';
import AddAmount from './AddAmount';

const { apiURL } = require('../../config/keys');

class Tablerow extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      isExpanded: false,
      isEditable: props.editable,
      editItemModalState: false,
      amountModal: false,
      salesHistoryModalState: false,
      subsData: [],
      purchases: [],
      amount: props.item.main_amount,
    };

    this.toggleEditItemModal = this.toggleEditItemModal.bind(this);
    this.toggleSalesHistoryModal = this.toggleSalesHistoryModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.getSubs = this.getSubs.bind(this);
    this.getPurchaseHistory = this.getPurchaseHistory.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.props.setNewAmount(this.props.id, e.target.value);
  }

  showOperationBox() {
    const { user } = this.props.auth;
    return ['SUB', 'MAIN'].includes(user.store_type) || user.isAdmin || user.isSeller;
  }

  showPurchaseHistory() {
    const { user } = this.props.auth;
    return ['MAIN'].includes(user.store_type) || user.isAdmin;
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.expand === true || nextProps.expanded === true) {
      this.setState({
        isExpanded: true,
        isEditable: nextProps.editable,
      });
    } else {
      this.setState({
        isExpanded: false,
        isEditable: nextProps.editable,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isExpanded && !prevState.isExpanded) {
      this.getSubs();
      if (this.showPurchaseHistory()) {
        this.getPurchaseHistory();
      }
    }
  }

  toggleDetails = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
    }), () => {
      if (this.state.isExpanded) {
        this.props.setExpanded(this.props.id);
      } else {
        this.props.setExpanded(null);
      }
    });
  }

  toggleEditItemModal() {
    this.setState((prev, props) => {
      const newState = !prev.editItemModalState;
      return { editItemModalState: newState };
    });
  }

  toggleSalesHistoryModal() {
    this.setState((prev, props) => {
      const newState = !prev.salesHistoryModalState;
      return { salesHistoryModalState: newState };
    });
  }

  toggleModal(name) {
    this.setState((prev, props) => ({ [name]: !prev[name] }));
  }

  getSubs() {
    const { item } = this.props;
    const query = {
      order_no: item._id.order_no,
    };
    axios.post(`${apiURL}api/items/get_subs`, query)
      .then((response) => {
        this.setState({
          subsData: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
      });
  }

  getPurchaseHistory() {
    const { item } = this.props;
    axios.post(`${apiURL}api/operations/get_sales`, {
      order_no: item._id.order_no,
      operation_type: 'PURCHASE',
      limit: 10,
    })
      .then((response) => {
        this.setState({
          purchases: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
      });
  }

  render() {
    const { item, isAdmin } = this.props;
    const { user } = this.props.auth;

    const purchaseHistory = this.state.purchases.map(i => <div>{moment(i.created).format('DD/MM/YYYY')} - {i.amount} tk</div>);

    if (isMobile) {
      if (!this.state.isExpanded) {
        return (
            <tbody>
                <tr style={ { backgroundColor: '#FFFFFF', border: 0, opacity: item._id.is_archived ? 1 : 1 } } onClick={ this.toggleDetails }>
                    <td style={ { paddingRight: 0 } }>
                        { item._id.is_archived
                          ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="6" cy="6" r="6" fill="#EEEEEE"/>
                          </svg>
                          : user.store_type === 'SUB' && item.my_amount > 0
                            ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#80B462"/>
                            </svg>
                            : user.store_type === 'SUB' && item.my_amount <= 0
                              ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="6" cy="6" r="5.5" stroke="#707070"/>
                              </svg>

                              : user.store_type === 'MAIN' && item.main_amount <= item.min_amount
                                ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="6" cy="6" r="6" fill="#EB5757"/>
                                </svg>
                                : user.store_type === 'MAIN' && item.main_amount > item.min_amount
                                  ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="6" cy="6" r="6" fill="#80B462"/>
                                  </svg>
                                  : null
                }
                    </td>
                    <th style={ { border: 0 } }>{item._id.order_no}</th>
                    <td style={ this.state.isExpanded ? styles.expandedNameMobile : styles.collapsedNameMobile }>{item._id.name}</td>
                    <td style={ item.main_amount >= item.min_amount ? styles.overMin : styles.underMin }>{item.main_amount}</td>
                </tr>
                <tr style={ { height: '5px' } }><td colSpan='100%'></td></tr>
            </tbody>
        );
      }
      return (
          <tbody>
              <tr className='block' style={ { backgroundColor: '#FFFFFF', border: 0, opacity: item._id.is_archived ? 1 : 1 } } >
                  <td colSpan='100%' style={ {
                    height: '50px', backgroundColor: 'white', paddingLeft: 0, paddingRight: 0,
                  } }>
                      <table class="is-fullwidth" style={ { width: '100%' } } onClick={ this.toggleDetails } >
                          <tbody>
                              <tr>
                                  <td style={ { paddingRight: 0 } }>
                                      { item._id.is_archived
                                        ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="6" cy="6" r="6" fill="#EEEEEE"/>
                                        </svg>
                                        : user.store_type === 'SUB' && item.my_amount > 0
                                          ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <circle cx="6" cy="6" r="6" fill="#80B462"/>
                                          </svg>
                                          : user.store_type === 'SUB' && item.my_amount <= 0
                                            ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="6" cy="6" r="5.5" stroke="#707070"/>
                                            </svg>

                                            : user.store_type === 'MAIN' && item.main_amount <= item.min_amount
                                              ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="6" cy="6" r="6" fill="#EB5757"/>
                                              </svg>
                                              : user.store_type === 'MAIN' && item.main_amount > item.min_amount
                                                ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="6" fill="#80B462"/>
                                                </svg>
                                                : null
              }
                                  </td>
                                  <th>Tellimisnumber</th>
                                  <th style={ { wordBreak: 'break-all' } }>{item._id.order_no}</th>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Triipkood</td>
                                  <td style={ { wordBreak: 'break-all' } }>{item._id.barcode}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Nimetus</td>
                                  <td style={ { wordBreak: 'break-all' } }>{item._id.name}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Seade</td>
                                  <td style={ { wordBreak: 'break-all' } }>{item._id.device}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Asukoht</td>
                                  <td style={ { wordBreak: 'break-all' } }>{item._id.location_in_warehouse}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Kogus pakis</td>
                                  <td>{item._id.amount_in_pack}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Hinnagrupp</td>
                                  <td><a style={ { color: 'black' } } target="_blank" rel="noopener noreferrer" href="http://www.gaspre.ee/hinnagrupid.pdf">{item._id.price_group}</a></td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Kesklaos(tk)</td>
                                  <td style={ item.main_amount >= item.min_amount ? styles.overMinDetail : styles.underMinDetail }>{item.main_amount}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Vaheladudes(tk)</td>
                                  <td>{item.sub_amount}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td colSpan='100%'>
                                      <table>
                                          {this.state.subsData.map(store => (
                                              <tr>
                                                  <td>{store.name}</td>
                                                  <td>{store.amount}</td>
                                              </tr>
                                          ))}
                                      </table>
                                  </td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Kommentaar</td>
                                  <td style={ { wordBreak: 'break-all' } }>{item._id.comment}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Tootja</td>
                                  <td style={ { wordBreak: 'break-all' } }>{item._id.producer}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td>Viimati lattu lisatud</td>
                                  <td>{purchaseHistory || '-'}</td>
                              </tr>
                              <tr>
                                  <td colSpan="3"></td>
                              </tr>
                          </tbody>
                      </table>
                      {this.showOperationBox() ? <Operationbox
                        isMobile={ isMobile }
                        isAdmin={ isAdmin }
                        item={ item }
                        toggleEditItemModal={ this.toggleEditItemModal }
                        toggleSalesHistoryModal={ this.toggleSalesHistoryModal }
                        toggleAmountModal={ () => this.toggleModal('amountModal') }
                      /> : null}

                  </td>
                  {this.state.editItemModalState ? <EditItem closeModal={ this.toggleEditItemModal } item={ item } /> : null}
                  {this.state.salesHistoryModalState ? <SalesHistory closeModal={ this.toggleSalesHistoryModal } subsData={ this.state.subsData } item= { item }/> : null }
                  {this.state.amountModal ? <AddAmount closeModal={ () => this.toggleModal('amountModal') } item={ item } /> : null}
              </tr>
              <tr style={ { height: '5px' } }><td colSpan='100%'></td></tr>
          </tbody>
      );
    }
    return (
        <tbody className='is-fullwidth'>
            <tr style={ { backgroundColor: '#FFFFFF', border: 0, opacity: item._id.is_archived ? 1 : 1 } } onClick={ this.toggleDetails }>
                <td style={ { border: 0 } }>
                    { item._id.is_archived
                      ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="6" fill="#EEEEEE"/>
                      </svg>
                      : user.store_type === 'SUB' && item.my_amount > 0
                        ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#80B462"/>
                        </svg>
                        : user.store_type === 'SUB' && item.my_amount <= 0
                          ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="6" cy="6" r="5.5" stroke="#707070"/>
                          </svg>

                          : user.store_type === 'MAIN' && item.main_amount < item.min_amount
                            ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#EB5757"/>
                            </svg>
                            : user.store_type === 'MAIN' && item.main_amount >= item.min_amount
                              ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="6" cy="6" r="6" fill="#80B462"/>
                              </svg>
                              : null
              }
                </td>
                <th style={ { border: 0 } }>{item._id.order_no}</th>
                <td class="is-hidden-mobile" style={ { border: 0 } }>{item._id.barcode}</td>
                <td style={ this.state.isExpanded ? styles.expandedName : styles.collapsedName }>{item._id.name}</td>
                <td class="is-hidden-touch" style={ this.state.isExpanded ? styles.expandedDevice : styles.collapsedDevice }>{item._id.device}</td>
                <td class="is-hidden-touch" style={ this.state.isExpanded ? styles.expandedDevice : styles.collapsedDevice }>{item._id.location_in_warehouse}</td>
                <td class="is-hidden-mobile" style={ { textAlign: 'right', border: 0 } }>{item._id.amount_in_pack}</td>
                <td class="is-hidden-mobile" style={ { textAlign: 'right', border: 0 } }><a style={ { color: 'black' } } target="_blank" rel="noopener noreferrer" href="http://www.gaspre.ee/hinnagrupid.pdf">{item._id.price_group}</a></td>
                <td style={ item.main_amount >= item.min_amount ? styles.overMin : styles.underMin }>
                    {this.state.isEditable ? <input class="input" name="amount" disabled={ this.props.isLoading ? 'disabled' : '' } value={ this.state.amount } onChange={ this.onChange } type="text" /> : item.main_amount}
                </td>
                <td class="is-hidden-mobile" style={ { textAlign: 'right', border: 0 } }>
                    {this.state.isExpanded
                      ? <div><div onClick={ this.toggleDetails }>{item.sub_amount}</div>
                          {this.state.subsData.map(store => (
                              <p>{store.name} - {store.amount}</p>
                          ))}
                      </div>
                      : <div><div onClick={ this.toggleDetails }>{item.sub_amount}</div></div>
                }
                </td>
                {item.in_store_amount > 0 ? <td style={ { textAlign: 'right', border: 0 } }>{item.in_store_amount}</td> : null}

                {this.state.editItemModalState ? <EditItem closeModal={ this.toggleEditItemModal } item={ item }/> : null}
                {this.state.amountModal ? <AddAmount closeModal={ () => this.toggleModal('amountModal') } item={ item }/> : null}
                {this.state.salesHistoryModalState ? <SalesHistory closeModal={ this.toggleSalesHistoryModal } subsData={ this.state.subsData } item= { item }/> : null }
            </tr>
            {this.state.isExpanded
              ? <tr style={ { backgroundColor: '#FFFFFF', border: 0 } }>
                  <td>Tootja</td>
                  <td colSpan="100%">{item._id.producer}</td>
              </tr>
              : null}
            {this.state.isExpanded && this.showPurchaseHistory()
              ? <tr style={ { backgroundColor: '#FFFFFF', border: 0 } }>
                  <td>Viimati lattu lisatud</td>
                  <td colSpan="100%">{purchaseHistory}</td>
              </tr>
              : null}
            {this.state.isExpanded && this.showOperationBox()
              ? <Operationbox
                  className='is-hidden-mobile'
                  isMobile={ isMobile }
                  isAdmin={ isAdmin }
                  item={ item }
                  toggleEditItemModal={ this.toggleEditItemModal }
                  toggleSalesHistoryModal={ this.toggleSalesHistoryModal }
                  toggleAmountModal={ () => this.toggleModal('amountModal') }
                  />
              : null
          }

            <tr style={ { height: '12px' } }><td colSpan='100%'></td></tr>
        </tbody>
    );
  }
}

Tablerow.propTypes = {
  item: PropTypes.object,
  isAdmin: PropTypes.bool,
  onClick: PropTypes.func,
  auth: PropTypes.object,
  getQuery: PropTypes.func,
  getItems: PropTypes.func,
  expand: PropTypes.number,
  expanded: PropTypes.bool,
  editable: PropTypes.bool,
  setExpanded: PropTypes.func,
  setNewAmount: PropTypes.func,
  isLoading: PropTypes.bool,
};

const styles = {
  collapsedDevice: {
    border: 0,
    // maxWidth: '420px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    maxWidth: 0,
  },
  expandedDevice: {
    border: 0,
    wordBreak: 'break-all',
  },
  collapsedName: {
    border: 0,
    // maxWidth: '10%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '20%',
    maxWidth: 0,
  },
  expandedNameMobile: {
    border: 0,
    wordBreak: 'break-all',
  },
  collapsedNameMobile: {
    border: 0,
    // maxWidth: '10%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '40%',
    maxWidth: 0,
  },
  expandedName: {
    border: 0,
    wordBreak: 'break-all',
  },
  overMin: {
    textAlign: 'right',
    border: 0,
    color: 'black',
  },
  underMin: {
    textAlign: 'right',
    border: 0,
    color: 'red',
  },
  overMinDetail: {
    color: 'black',
  },
  underMinDetail: {
    color: 'red',
  },
  rowNormal: {
    backgroundColor: '#FFFFFF',
    border: 0,
  },
  rowWarning: {
    backgroundColor: 'red',
    border: 2,
    borderColor: '#FFFFFF',
    borderLeftColor: 'red',
  },
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getQuery, getItems })(Tablerow);
