import {
  GET_ITEMS,
  TABLE_LOADING,
  GET_STORES,
} from '../actions/types';

const initialState = {
  data: [],
  stores: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TABLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case GET_STORES:
      return {
        ...state,
        stores: action.payload,
      };
    default:
      return state;
  }
}
