/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { createHashHistory } from 'history';

export const history = createHashHistory();


const { apiURL } = require('../../config/keys');

class EditItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_order_no: '',
      old_amount: '',
      order_no: '',
      barcode: '',
      name: '',
      device: '',
      price_group: '',
      amount_in_pack: '',
      amount: '',
      min_amount: '',
      location_in_warehouse: '',
      producer: '',
      is_hidden: false,
      is_archived: false,
      comment: '',
      error: false,

    };
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.addItem = this.addItem.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;
    this.setState({
      old_order_no: item._id.order_no,
      old_amount: item.main_amount,
      order_no: item._id.order_no,
      barcode: item._id.barcode,
      name: item._id.name,
      device: item._id.device,
      price_group: item._id.price_group,
      amount_in_pack: item._id.amount_in_pack,
      amount: item.main_amount,
      min_amount: item.min_amount,
      location_in_warehouse: item._id.location_in_warehouse,
      producer: item._id.producer,
      is_hidden: item._id.is_hidden,
      is_archived: item._id.is_archived,
      comment: item._id.comment,
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCheckboxChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  addItem() {
    const { user } = this.props.auth;
    const item = {
      old_order_no: this.state.old_order_no,
      old_amount: this.state.old_amount,
      name: this.state.name,
      order_no: this.state.order_no,
      barcode: this.state.barcode,
      device: this.state.device,
      price_group: this.state.price_group,
      amount_in_pack: this.state.amount_in_pack,
      comment: this.state.comment,
      min_amount: this.state.min_amount,
      location_in_warehouse: this.state.location_in_warehouse,
      producer: this.state.producer,
      is_hidden: this.state.is_hidden,
      is_archived: this.state.is_archived,
      store: user.id,
      amount: this.state.amount,
    };
    axios.post(`${apiURL}api/items/edit/`, item)
      .then((response) => {
        console.log(response);
        this.close();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
        });
        window.alert(`Midagi läks valesti\n ${error}`);
      });
  }

  close() {
    this.setState({
      error: false,
    }, () => {
      this.props.closeModal();
    });
    history.push('/');
  }


  render() {
    const { modalState, closeModal } = this.props;
    return (
        <div className="modal is-active" >
            <div className="modal-background" onClick={ closeModal } />
            <div className="modal-card" style={ styles.container }>
                <header className="modal-card-head" style={ {
                  borderRadius: 0, borderColor: '#F9F9FA', backgroundColor: '#F9F9FA', padding: '16px',
                } }>
                    <p className="modal-card-title"><h5 style={ styles.header }>Muuda varuosa</h5></p>
                    <button className="delete" onClick={ closeModal } />
                </header>
                <section className="modal-card-body" style={ styles.sectionContainer }>
                    <div className="columns is-centered">
                        <div className="column is-3"><p style={ styles.label }>Tellimisnumber</p><input class="input" name="order_no" value={ this.state.order_no } onChange={ this.onChange } type="text" style={ {
                          height: '42px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0,
                        } }/></div>
                        <div className="column is-3"><p style={ styles.label }>Triipkood</p><input class="input" name="barcode" value={ this.state.barcode } onChange={ this.onChange } type="text" style={ {
                          height: '42px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0,
                        } }/></div>
                        <div className="column is-6"><p style={ styles.label }>Nimi</p><input class="input" name="name" value={ this.state.name } onChange={ this.onChange } type="text" style={ {
                          height: '42px', borderColor: '#BDBDBD', borderWidth: 1, borderRadius: 0,
                        } }/></div>
                    </div>
                    <div className="columns is-centered">
                        <div className="column is-4"><p style={ styles.label }>Kogus(tk)</p><input class="input" name="amount" value={ this.state.amount } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                        <div className="column is-4"><p style={ styles.label }>Koguse piirmäär</p><input class="input" name="min_amount" value={ this.state.min_amount } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                        <div className="column is-4"><p style={ styles.label }>Tootja</p><input class="input" name="producer" value={ this.state.producer } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                    </div>
                    <div className="columns is-centered">
                        <div className="column is-4"><p style={ styles.label }>Hinnagrupp</p><input class="input" name="price_group" value={ this.state.price_group } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                        <div className="column is-4"><p style={ styles.label }>Kogus pakis</p><input class="input" name="amount_in_pack" value={ this.state.amount_in_pack } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                        <div className="column is-4"><p style={ styles.label }>Asukoht laos</p><input class="input" name="location_in_warehouse" value={ this.state.location_in_warehouse } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                    </div>
                    <div className="columns is-centered">
                        <div className="column is-12"><p style={ styles.label }>Seade</p><input class="input" name="device" value={ this.state.device } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                    </div>
                    <div className="columns is-centered">
                        <div className="column is-12"><p style={ styles.label }>Kommentaar</p><input class="input" name="comment" value={ this.state.comment } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                    </div>
                    <div className="columns is-centered">
                        <div className="column is-6"><p style={ styles.label }>Arhiveeri</p><input name="is_archived" value="true" checked={ this.state.is_archived } onChange={ this.onCheckboxChange } type="checkbox" style={ styles.input }/></div>
                        <div className="column is-6"><p style={ styles.label }>Peida</p><input name="is_hidden" value="true" checked={ this.state.is_hidden } onChange={ this.onCheckboxChange } type="checkbox" style={ styles.input }/></div>
                    </div>
                </section>
                <footer className="modal-card-foot" style={ styles.footerContainer }>
                    <a className="button" onClick={ this.close } style={ styles.cancelButton }>KATKESTA</a>
                    <a className="button" onClick={ this.addItem } style={ styles.submitButton }>SALVESTA</a>

                </footer>
            </div>
        </div>
    );
  }
}


EditItem.propTypes = {
  closeModal: PropTypes.func,
  modalState: PropTypes.bool,
  showError: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  item: PropTypes.object,
};

const styles = {
  sectionContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    padding: '16px',
  },
  container: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
  },
  footerContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    justifyContent: 'flex-end',
    padding: '16px',
  },
  input: {
    height: '42px',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderRadius: 0,
  },
  header: {
    fontSize: 24,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#333333',
  },
  label: {
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#4F4F4F',
    letterSpacing: '0.75px',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: '1px',
    borderRadius: 0,
    color: '#677C98',
    marginRight: '4px',
  },
  submitButton: {
    backgroundColor: '#677C98',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: '1px',
    borderRadius: 0,
    color: '#FFFFFF',
    marginLeft: '4px',
    float: 'right',
  },
  errorMessage: {
    textAlign: 'center',
    color: 'red',
  },
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(EditItem);
