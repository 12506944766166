/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { createHashHistory } from 'history';

export const history = createHashHistory();


const { apiURL } = require('../../config/keys');

class AddAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_no: '',
      amount: '',
      error: false,
    };
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submit() {
    const { user } = this.props.auth;
    const { item } = this.props;

    const request = {
      order_no: item._id.order_no,
      store: user.id,
      amount: this.state.amount,
    };
    axios.post(`${apiURL}api/items/add_amount`, request)
      .then(() => {
        this.close();
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
        window.alert(`Midagi läks valesti\n ${error}`);
      });
  }

  close() {
    this.setState({
      error: false,
    }, () => {
      this.props.closeModal();
    });
    history.push('/');
  }


  render() {
    const { closeModal } = this.props;
    return (
        <div className="modal is-active" >
            <div className="modal-background" onClick={ closeModal } />
            <div className="modal-card" style={ styles.container }>
                <header className="modal-card-head" style={ {
                  borderRadius: 0, borderColor: '#F9F9FA', backgroundColor: '#F9F9FA', padding: '16px',
                } }>
                    <p className="modal-card-title"><h5 style={ styles.header }>Lisa lattu</h5></p>
                    <button className="delete" onClick={ closeModal } />
                </header>
                <section className="modal-card-body" style={ styles.sectionContainer }>
                    <div className="columns">
                        <div className="column is-4"><p style={ styles.label }>Kogus(tk)</p><input class="input" name="amount" value={ this.state.amount } onChange={ this.onChange } type="text" style={ styles.input }/></div>
                    </div>
                </section>
                <footer className="modal-card-foot" style={ styles.footerContainer }>
                    <a className="button" onClick={ this.close } style={ styles.cancelButton }>KATKESTA</a>
                    <a className="button" onClick={ this.submit } style={ styles.submitButton }>SALVESTA</a>
                </footer>
            </div>
        </div>
    );
  }
}


AddAmount.propTypes = {
  closeModal: PropTypes.func,
  modalState: PropTypes.bool,
  showError: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  item: PropTypes.object,
};

const styles = {
  sectionContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    padding: '16px',
  },
  container: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
  },
  footerContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    justifyContent: 'flex-end',
    padding: '16px',
  },
  input: {
    height: '42px',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderRadius: 0,
  },
  header: {
    fontSize: 24,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#333333',
  },
  label: {
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#4F4F4F',
    letterSpacing: '0.75px',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: '1px',
    borderRadius: 0,
    color: '#677C98',
    marginRight: '4px',
  },
  submitButton: {
    backgroundColor: '#677C98',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: '1px',
    borderRadius: 0,
    color: '#FFFFFF',
    marginLeft: '4px',
    float: 'right',
  },
  errorMessage: {
    textAlign: 'center',
    color: 'red',
  },
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AddAmount);
