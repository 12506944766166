import axios from 'axios';

import {
  GET_ITEMS,
  TABLE_LOADING,
  GET_STORES,
} from './types';

const { apiURL } = require('../config/keys');

let latestUrl = '';
let latestBody = {};

export const downloadCSV = () => (dispatch) => {
  if (latestUrl.includes('find_barcode2')) {
    axios.post(`${latestUrl}?csv=true`, latestBody, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'download.csv');
        document.body.appendChild(link);
        link.click();
      });
  } else {
    axios.get(`${latestUrl}?csv=true`, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'download.csv');
        document.body.appendChild(link);
        link.click();
      });
  }
};

export const refresh = () => (dispatch) => {
  if (latestUrl.includes('find_barcode2')) {
    return getQuery(latestBody)(dispatch);
  }
  return getItems()(dispatch);
};

// Get Items table
export const getItems = () => (dispatch) => {
  dispatch(setTableLoading());
  const url = `${apiURL}api/items/table2/`;
  axios.get(url)
    .then((response) => {
      latestUrl = url;
      latestBody = {};
      dispatch({
        type: GET_ITEMS,
        payload: response.data,
      });
    })
    .catch((error) => {
      // handle error
      console.log(error);
    });
};

// Search from table
export const getQuery = queryText => (dispatch) => {
  dispatch(setTableLoading());
  const url = `${apiURL}api/items/find_barcode2/`;
  axios.post(url, queryText)
    .then((response) => {
      // handle success
      latestUrl = url;
      latestBody = queryText;
      dispatch({
        type: GET_ITEMS,
        payload: response.data,
      });
    })
    .catch((error) => {
      // handle error
      console.log(error);
    });
};

export const getStores = () => (dispatch) => {
  axios.get(`${apiURL}api/stores/all`)
    .then(({ data }) => {
      dispatch({
        type: GET_STORES,
        payload: data,
      });
    }).catch((error) => {
      dispatch({
        type: GET_STORES,
        payload: [],
      });
    });
};


// Set loading state
export const setTableLoading = () => ({
  type: TABLE_LOADING,
});
