/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { isMobile, isDesktop } from 'react-device-detect';
import Sticky from 'react-sticky-el';
import { createHashHistory } from 'history';

export const history = createHashHistory();

const { apiURL } = require('../../config/keys');

class Operationbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 1,
      comment: '',
      error: false,

    };
    this.onChange = this.onChange.bind(this);
    this.close = this.close.bind(this);
    this.mainToSub = this.mainToSub.bind(this);
    this.subToMain = this.subToMain.bind(this);
    this.sale = this.sale.bind(this);
    this.addAmount = this.addAmount.bind(this);
    this.removeAmount = this.removeAmount.bind(this);
  }

  componentDidMount() {
    const { item, isAdmin } = this.props;
    let comment = '';
    if (isAdmin) {
      comment = item._id.comment;
    }
    this.setState({
      comment,
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  mainToSub() {
    const { user } = this.props.auth;
    const operation = {
      operation_type: 'MAIN_TO_SUB',
      order_no: this.props.item._id.order_no,
      comment: this.state.comment,
      amount: this.state.amount,
      destId: user.id,
    };
    axios.post(`${apiURL}api/operations/main_to_sub`, operation)
      .then((response) => {
        console.log(response);
        window.alert(`Võetud ${operation.amount} tk`);
        history.push('/');
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
        });
        window.alert(`Midagi läks valesti\n ${error}`);
      });
  }

  getSubs() {
    const { user } = this.props.auth;
    const { item } = this.props;
    const query = {
      order_no: item._id.order_no,
    };
    axios.post(`${apiURL}api/items/get_subs`, query)
      .then((response) => {
        console.log(response);
        this.setState({
          subsData: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
        });
      });
  }

  subToMain() {
    const { user } = this.props.auth;
    const operation = {
      operation_type: 'SUB_TO_MAIN',
      order_no: this.props.item._id.order_no,
      comment: this.state.comment,
      amount: this.state.amount,
      sourceId: user.id,
    };
    axios.post(`${apiURL}api/operations/sub_to_main`, operation)
      .then((response) => {
        console.log(response);
        window.alert(`Pandud ${operation.amount} tk`);
        history.push('/');
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
        });
        window.alert(`Midagi läks valesti\n ${error}`);
      });
  }

  sale() {
    const { user } = this.props.auth;
    const operation = {
      operation_type: 'SALE',
      order_no: this.props.item._id.order_no,
      comment: this.state.comment,
      amount: this.state.amount,
      sourceId: user.id,
    };
    axios.post(`${apiURL}api/operations/sale`, operation)
      .then((response) => {
        console.log(response);
        window.alert(`Müüdud ${operation.amount} tk`);
        history.push('/');
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
        });
        window.alert(`Midagi läks valesti\nSisesta kommentaar\n ${error}`);
      });
  }

  addAmount() {
    const prev = this.state.amount;
    this.setState({
      amount: prev + 1,
    });
  }

  removeAmount() {
    const prev = this.state.amount;
    if (prev > 1) {
      this.setState({
        amount: prev - 1,
      });
    }
  }

  close() {
    this.setState({
      error: false,
    }, () => {
      this.props.closeModal();
    });
  }

  isStore() {
    const { user } = this.props.auth;
    return ['MAIN', 'SUB'].includes(user.store_type) || user.isAdmin;
  }

  render() {
    const {
      isAdmin,
      toggleEditItemModal,
      toggleSalesHistoryModal,
      toggleAmountModal,
    } = this.props;

    const adminButtons = [];

    if (isAdmin) {
      adminButtons.push(
          <div className='is-pulled-left'>
              <button class="button" onClick={ toggleAmountModal } style={ styles.button }>LISA LATTU</button>
          </div>,
      );
      adminButtons.push(
          <div className='is-pulled-left'>
              <button class="button" onClick={ toggleEditItemModal } style={ styles.button }>MUUDA</button>
          </div>,
      );
      adminButtons.push(
          <div className='is-pulled-right'>
              <button class="button" onClick= { toggleSalesHistoryModal } style={ styles.button }>AJALUGU</button>
          </div>,
      );
    }

    if (isMobile) {
      if (isAdmin) {
        return (
            <Sticky mode='bottom' boundaryElement='.block' hideOnBoundaryHit={ true } bottomOffset={ 90 }>
                <div
              className='field is-grouped is-grouped-centered'
              style={ {
                backgroundColor: 'white',
                marginLeft: '16px',
                marginRight: '16px',
                justifyContent: 'space-between',
              } }>
                    { adminButtons }
                </div>
            </Sticky>
        );
      }
      return (
          <Sticky mode='bottom' boundaryElement='.block' hideOnBoundaryHit={ true } bottomOffset={ 90 }>
              <div style={ { backgroundColor: 'white' } }>
                  <div className='field is-grouped' style={ { backgroundColor: 'white', marginLeft: '16px' } }>
                      <div style={ { display: 'flex', alignItems: 'center' } }>
                                  Kogus
                      </div>
                      <div>
                          <a class="button is-fullwidth" style={ { borderRadius: 0, borderColor: 'white' } } onClick={ this.removeAmount }>
                              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g opacity="0.5">
                                      <circle cx="10.0234" cy="10.0001" r="9.25" stroke="#677C98" stroke-width="1.5"/>
                                      <line x1="5.77344" y1="9.74976" x2="13.7734" y2="9.74976" stroke="#677C98" stroke-width="1.5" stroke-linecap="round"/>
                                  </g>
                              </svg>
                          </a>
                      </div>
                      <div style={ { display: 'flex', alignItems: 'center' } }>
                          {this.state.amount}
                      </div>
                      <div>
                          <a class="button is-fullwidth" style={ { borderRadius: 0, borderColor: 'white' } } onClick={ this.addAmount }>
                              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="10.4619" cy="10" r="9.25" stroke="#677C98" stroke-width="1.5"/>
                                  <line x1="6.21191" y1="9.75012" x2="14.2119" y2="9.75012" stroke="#677C98" stroke-width="1.5" stroke-linecap="round"/>
                                  <line x1="10.2119" y1="5.5" x2="10.2119" y2="13.5" stroke="#677C98" stroke-width="1.5" stroke-linecap="round"/>
                              </svg>
                          </a>
                      </div>
                  </div>
                  <div className="columns is-centered is-fullwidth" style={ { marginLeft: '16px', marginRight: '16px' } }>
                      <input class="input" name="comment" type="text" placeholder="Kommentaar" onChange={ this.onChange } value={ this.state.comment } style={ styles.input }/>
                  </div>
                  <div className='field is-grouped is-grouped-centered' style={ {
                    backgroundColor: 'white', marginLeft: '16px', marginRight: '16px', justifyContent: 'space-between',
                  } }>
                      {this.isStore()
                        ? <div>
                            <a class="button is-fullwidth" onClick={ this.mainToSub } style={
                        {
                          backgroundColor: '#ffffff', borderColor: '#677C98', borderWidth: 1, borderRadius: 0, color: '#677C98',
                        }
                      }>VÕTA</a>
                        </div> : null}
                      <div>
                          <a class="button is-fullwidth" onClick={ this.sale } style={
                        {
                          backgroundColor: '#ffffff', borderColor: '#677C98', borderWidth: 1, borderRadius: 0, color: '#677C98',
                        }
                      }>MÜÜ</a>
                      </div>
                      {this.isStore()
                        ? <div>
                            <a class="button is-fullwidth" onClick={ this.subToMain } style={
                        {
                          backgroundColor: '#ffffff', borderColor: '#677C98', borderWidth: 1, borderRadius: 0, color: '#677C98',
                        }
                      }>PANE</a>
                        </div> : null}
                      { this.isStore()
                        ? <div className='is-pulled-right'>
                            <button class="button" onClick= { toggleSalesHistoryModal } style={ styles.button }>AJALUGU</button>
                        </div> : null }
                  </div>
              </div>
          </Sticky>
      );
    }
    return (
        <tr className='is-hidden-mobile' style={ { height: '24px', backgroundColor: 'white' } }>
            <td></td>
            <td colSpan='9'>

                {isAdmin
                  ? <div className='columns'>
                      <div className=" column is-6" >
                          <div style={ { display: 'inline' } }><b>Kommentaar: </b>{ this.state.comment }</div>
                      </div>
                      <div className='column is-2'></div>
                      <div className='column is-4' style={ { paddingLeft: 0, paddingRight: 0 } }>
                          <div className='field is-grouped is-grouped-right' style={ {
                            backgroundColor: 'white', width: '100%', marginRight: 0, paddingRight: '12px',
                          } }>
                              { adminButtons }
                          </div>
                      </div>
                  </div>
                  : <div className='columns' >
                      <div className='column is-2'>
                          <div className='field is-grouped' style={ { backgroundColor: 'white' } }>
                              <div style={ { display: 'flex', alignItems: 'center' } }>
                                  Kogus
                              </div>
                              <div>
                                  <a class="button is-fullwidth" style={ { borderRadius: 0, borderColor: 'white' } } onClick={ this.removeAmount }>
                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g opacity="0.5">
                                              <circle cx="10.0234" cy="10.0001" r="9.25" stroke="#677C98" stroke-width="1.5"/>
                                              <line x1="5.77344" y1="9.74976" x2="13.7734" y2="9.74976" stroke="#677C98" stroke-width="1.5" stroke-linecap="round"/>
                                          </g>
                                      </svg>
                                  </a>
                              </div>
                              <div style={ { display: 'flex', alignItems: 'center' } }>
                                  {this.state.amount}
                              </div>
                              <div>
                                  <a class="button is-fullwidth" style={ { borderRadius: 0, borderColor: 'white' } } onClick={ this.addAmount }>
                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="10.4619" cy="10" r="9.25" stroke="#677C98" stroke-width="1.5"/>
                                          <line x1="6.21191" y1="9.75012" x2="14.2119" y2="9.75012" stroke="#677C98" stroke-width="1.5" stroke-linecap="round"/>
                                          <line x1="10.2119" y1="5.5" x2="10.2119" y2="13.5" stroke="#677C98" stroke-width="1.5" stroke-linecap="round"/>
                                      </svg>
                                  </a>
                              </div>
                          </div>
                      </div>
                      <div className=" column is-6" >
                          <div><input class="input" name="comment" type="text" placeholder="Kommentaar" value={ this.state.comment } style={ styles.input } onChange={ this.onChange }/></div>
                      </div>
                      <div className='column is-4' style={ { paddingLeft: 0, paddingRight: 0 } }>
                          <div className='field is-grouped is-grouped-right' style={ {
                            backgroundColor: 'white', width: '100%', marginRight: 0, paddingRight: '12px',
                          } }>
                              { this.isStore()
                                ? <div>
                                    <button class="button is-block"onClick={ this.mainToSub } style={ {
                                      backgroundColor: '#ffffff', borderColor: '#677C98', borderWidth: 1, borderRadius: 0, color: '#677C98', marginLeft: '5px', marginRight: '5px', width: '105px',
                                    } }>VÕTA</button>
                                </div> : null }
                              <div>
                                  <button class="button is-block" onClick={ this.sale } style={ {
                                    backgroundColor: '#ffffff', borderColor: '#677C98', borderWidth: 1, borderRadius: 0, color: '#677C98', marginLeft: '5px', marginRight: '5px', width: '105px',
                                  } }>MÜÜ</button>
                              </div>
                              { this.isStore()
                                ? <div>
                                    <button class="button is-block" onClick={ this.subToMain } style={ {
                                      backgroundColor: '#ffffff', borderColor: '#677C98', borderWidth: 1, borderRadius: 0, color: '#677C98', marginLeft: '5px', marginRight: '0px', width: '105px',
                                    } }>PANE</button>
                                </div> : null }
                              { this.isStore()
                                ? <div className='is-pulled-right'>
                                    <button class="button" onClick= { toggleSalesHistoryModal } style={ styles.button }>AJALUGU</button>
                                </div> : null }
                          </div>
                      </div>
                  </div>
                    }
            </td>
        </tr>
    );
  }
}


Operationbox.propTypes = {
  closeModal: PropTypes.func,
  modalState: PropTypes.bool,
  showError: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  item: PropTypes.object,
  title: PropTypes.string,
  operationType: PropTypes.string.isRequired,
  subsData: PropTypes.object,
  isAdmin: PropTypes.bool,
  toggleEditItemModal: PropTypes.func,
  toggleSalesHistoryModal: PropTypes.func,
  toggleAmountModal: PropTypes.func,
};

const styles = {
  container: {
    width: '1000px',
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
  },

  mobileContainer: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
  },
  input: {
    height: '36px',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderRadius: 0,
    display: 'flex',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  header: {
    fontSize: 24,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#333333',
  },
  label: {
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#4F4F4F',
    letterSpacing: '0.75px',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: 2,
    borderRadius: 0,
    color: '#677C98',
    marginRight: '4px',
  },
  submitButton: {
    backgroundColor: '#677C98',
    width: '119px',
    height: '36px',
    borderColor: '#677C98',
    borderWidth: 2,
    borderRadius: 0,
    color: '#FFFFFF',
    marginLeft: '4px',
  },
  errorMessage: {
    textAlign: 'center',
    color: 'red',
  },
  overMin: {
    textAlign: 'center',
    border: 0,
    color: 'black',
  },
  underMin: {
    textAlign: 'center',
    border: 0,
    color: 'red',
  },
  overMinDetail: {
    border: 0,
    color: 'black',
  },
  underMinDetail: {
    border: 0,
    color: 'red',
  },
  rowNormal: {
    backgroundColor: '#FFFFFF',
    border: 0,
  },
  rowWarning: {
    backgroundColor: 'red',
    border: 2,
    borderColor: '#FFFFFF',
    borderLeftColor: 'red',
  },
  button: {
    backgroundColor: '#ffffff',
    borderColor: '#677C98',
    borderWidth: 1,
    borderRadius: 0,
    color: '#677C98',
    marginLeft: '10px',
    marginRight: '10px',
    whiteSpace: 'normal',
    overflow: 'hidden',
  },
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Operationbox);
