/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import Quagga from 'quagga';
import PropTypes from 'prop-types';


class Scanner extends Component {
  constructor() {
    super();
    this.state = {
      results: [],
    };
  }

  componentDidMount() {
    Quagga.init({
      inputStream: {
        type: 'LiveStream',
        constraints: {
          width: 640,
          height: 480,
          facingMode: 'environment', // or user
        },
        target: document.querySelector('#scanner'),
      },
      locator: {
        patchSize: 'medium',
        halfSample: true,
      },
      numOfWorkers: 4,
      decoder: {
        readers: ['code_128_reader', 'ean_reader', 'code_39_reader'],
      },
      locate: true,
    }, (err) => {
      if (err) {
        return console.log(err);
      }
      Quagga.start();
    });
    Quagga.onDetected(this.onDetected);
  }

  componentWillUnmount() {
    Quagga.offDetected(this.onDetected);
    Quagga.stop();
  }

  onDetected = (result) => {
    /* this.props.onDetected(result.codeResult.code);
    console.log(`Scan result: ${result.codeResult.code}`);
    Quagga.stop();
    this.close(); */
    this.state.results.push(result.codeResult.code);

    if (this.state.results.length > 10) {
      let mf = 1;
      let m = 0;
      let item;
      const sampleArray = this.state.results.slice(1).slice(-10);
      for (let i = 0; i < sampleArray.length; i++) {
        for (let j = i; j < sampleArray.length; j++) {
          if (sampleArray[i] === sampleArray[j]) m++;
          if (mf < m) {
            mf = m;
            item = sampleArray[i];
          }
        }
        m = 0;
      }

      if (mf > 6) {
        this.props.onDetected(item);
        Quagga.stop();
        this.close();
      }
    }

    // console.log(result.codeResult.code);
  }

  close() {
    this.props.closeModal();
  }

  render() {
    return (
        <div className="modal is-active">
            <div className="modal=background"/>
            <div className="modal-card" style={ styles.container }>
                <header className="modal-card-head" style={ { borderRadius: 0, borderColor: '#F9F9FA', backgroundColor: '#F9F9FA' } }>
                    <p className="modal-card-title"><h5 style={ styles.header }>Skaneeri</h5></p>
                    <button className="delete" onClick={ this.props.closeModal }/>
                </header>
                <section className="modal-card-body" style={ styles.container }>
                    <div id='scanner'>
                    </div>
                </section>

            </div>
        </div>
    );
  }
}

Scanner.PropTypes = {
  closeModal: PropTypes.func,
  onDetected: PropTypes.func,
};

const styles = {
  container: {
    borderRadius: 0,
    borderColor: '#F9F9FA',
    backgroundColor: '#F9F9FA',
    overflow: 'hidden',
  },
  input: {
    height: '42px',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderRadius: 0,
  },
  header: {
    fontSize: 24,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    color: '#333333',
  },
};

export default Scanner;
