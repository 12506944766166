import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tablehead from './Tablehead';
import Tablebody from './Tablebody';

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditable: false,
    };
  }

  componentDidMount(props) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    } else {
      this.updateIsEditable();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateIsEditable();
    }
  }

  updateIsEditable() {
    const queryParams = new URLSearchParams(this.props.location.search || '');
    const isEditable = queryParams.get('inventuur') === 'true';

    if (this.state.isEditable !== isEditable) {
      this.setState({ isEditable });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.auth.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
        <div className="table" class="container">
            <Tablehead history={ this.props.history } location={ this.props.location } isInventuur={ this.state.isEditable } />
            <Tablebody isEditable={ this.state.isEditable } />

        </div>
    );
  }
}

Table.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});


export default withRouter(connect(mapStateToProps)(Table));
