/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import Tablerow from './Tablerow';
import { getItems, refresh, downloadCSV } from '../../actions/tableActions';
import store from '../../store';

const { apiURL } = require('../../config/keys');

class Tablebody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      amounts: {},
      expand: false,
      expandedIndex: '',
      location: props.location,
      isLoading: false,
    };
    this.setExpanded = this.setExpanded.bind(this);
    this.setNewAmount = this.setNewAmount.bind(this);
    this.saveAll = this.saveAll.bind(this);
    this.onDownloadCSV = this.onDownloadCSV.bind(this);
  }

  onDownloadCSV() {
    this.props.downloadCSV();
  }

  saveAll() {
    if (this.state.isLoading) {
      return;
    }

    const request = Object.keys(this.state.amounts)
      .map(key => ({
        order_no: key,
        amount: this.state.amounts[key],
        old_amount: this.props.items.data.find(item => item._id.order_no === key).main_amount,
      }));

    this.setState({
      isLoading: true,
    });

    axios.put(`${apiURL}api/items/update_amounts`, {
      items: request,
    })
      .then(() => {
        this.props.refresh();
        this.setState({
          amounts: {},
        });
        window.alert('Kogused salvestatud');
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
        window.alert(`Midagi läks valesti\n ${error}`);
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  setExpanded(index) {
    this.setState({
      expandedIndex: index,
    });
  }

  setNewAmount(index, amount) {
    this.state.amounts[index] = amount;
  }

  render() {
    const { user } = this.props.auth;
    const { data } = this.props.items;

    const jsx = [];
    data.forEach((item, index) => {
      jsx.push(<Tablerow key={ item._id.order_no } id={ item._id.order_no } item={ item } isAdmin={ user.store_type === 'MAIN' } expand={ data.length === 1 } setExpanded={ this.setExpanded } expanded={ item._id.order_no === this.state.expandedIndex && isMobile } editable={ this.props.isEditable } setNewAmount={ this.setNewAmount } isLoading={ this.isLoading } />);
    });
    return (
        <div>
            <table class="table is-fullwidth" style={ { backgroundColor: 'transparent', width: '100%' } }>
                <thead class="is-fullwidth">
                    <tr>
                        <th></th>
                        <th>Tellimisnumber</th>
                        <th class="is-hidden-mobile">Triipkood</th>
                        <th>Nimetus</th>
                        <th class="is-hidden-touch">Seade</th>
                        <th class="is-hidden-touch">Asukoht</th>
                        <th style={ { textAlign: 'right' } } class="is-hidden-mobile">TK/P</th>
                        <th style={ { textAlign: 'right' } } class="is-hidden-mobile">PG</th>
                        <th style={ { textAlign: 'right' } }>{isMobile ? 'Tk' : 'Keskladu(tk)'}</th>
                        <th style={ { textAlign: 'right' } } class="is-hidden-mobile">Vaheladudes(tk)</th>
                        {data[0] && data[0].in_store_amount > 0 ? <th style={ { textAlign: 'right' } } class="is-hidden-mobile">Valitud(tk)</th> : null}
                    </tr>
                </thead>

                {jsx}

            </table>

            {this.props.isEditable
              ? <div className="column is-3" style={ isMobile ? styles.addButtonMobile : styles.addButton }>
                  <a className="button is-fullwidth" onClick={ this.saveAll } style={
                        {
                          backgroundColor: 'green', height: '36px', borderColor: 'green', borderWidth: 2, borderRadius: 0, color: '#FFFFFF',
                        }
                      }>{ this.state.isLoading ? 'Salvestan...' : 'Salvesta kõik kogused' }</a>
              </div>
              : null }
            {this.props.isEditable
              ? <div className="column is-3" style={ isMobile ? styles.addButtonMobile : styles.addButton }>
                  <a className="button is-fullwidth" onClick={ this.onDownloadCSV } style={
                        {
                          backgroundColor: 'grey', height: '36px', borderColor: 'grey', borderWidth: 2, borderRadius: 0, color: '#FFFFFF',
                        }
                      }>Lae alla CSV</a>
              </div>
              : null }
        </div>
    );
  }
}

const styles = {
  addButton: { marginRight: '0px', marginLeft: '0px' },
  addButtonMobile: { marginRight: '8px', marginLeft: '8px' },
};


Tablebody.propTypes = {
  auth: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  getItems: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  items: state.items,
});

export default connect(mapStateToProps, { getItems, downloadCSV, refresh })(withRouter(Tablebody));
